var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "my-container" }, [
    _c(
      "div",
      { staticClass: "item tool-bar" },
      [
        _c(
          "CRow",
          [
            _c("CCol", [
              _c("span", { staticStyle: { "padding-left": "2mm" } }, [
                _vm._v("医療機関名:" + _vm._s(_vm.printHeader.hospitalName))
              ])
            ]),
            _c("CCol", { staticClass: "text-right" }, [
              _vm._v(
                " グラフ表示期間:" +
                  _vm._s(_vm.printHeader.printFormTo) +
                  " 印刷日:" +
                  _vm._s(_vm.printHeader.toDay) +
                  " "
              )
            ])
          ],
          1
        ),
        _c(
          "CRow",
          [
            _c("CCol", { staticClass: "pl-3" }, [
              _c("span", { staticStyle: { "padding-left": "2mm" } }, [
                _vm._v("処方医:" + _vm._s(_vm.printHeader.doctorName) + " ")
              ])
            ]),
            _c("CCol", { staticClass: "text-right" }, [
              _vm._v(
                " 販売店:" +
                  _vm._s(_vm.printHeader.agencyName) +
                  " " +
                  _vm._s(_vm.printHeader.agencyBranchName) +
                  " "
              )
            ])
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "left mt-2" },
      [
        _c("div", [
          _c(
            "span",
            {
              staticStyle: {
                width: "15mm",
                display: "inline-block",
                "font-size": "4mm",
                color: "black"
              }
            },
            [_vm._v("ID")]
          ),
          _c("span", { staticStyle: { "font-size": "4mm", color: "black" } }, [
            _vm._v(_vm._s(_vm.karuteNo))
          ])
        ]),
        _c("div", [
          _c(
            "span",
            {
              staticStyle: {
                width: "15mm",
                display: "inline-block",
                "font-size": "4mm",
                color: "black"
              }
            },
            [_vm._v("氏名")]
          ),
          _c("span", { staticStyle: { "font-size": "4mm", color: "black" } }, [
            _vm._v(_vm._s(_vm.patientName))
          ])
        ]),
        _vm._m(0),
        _c(
          "CRow",
          { staticClass: "patient-info no-print-area" },
          [
            _c("CCol", { staticClass: "patient-info-item ml-3 text-center" }, [
              _vm._v("安静時")
            ]),
            _c("CCol", { staticClass: "patient-info-item ml-3 text-center" }, [
              _vm._v("労作時")
            ]),
            _c("CCol", { staticClass: "patient-info-item ml-3 text-center" }, [
              _vm._v("就寝時")
            ])
          ],
          1
        ),
        _c(
          "CRow",
          { staticClass: "patient-info no-print-area" },
          [
            _c("CCol", { staticClass: "patient-info-item ml-3 text-center" }, [
              _vm._v(_vm._s(_vm.prescription.rest))
            ]),
            _c("CCol", { staticClass: "patient-info-item ml-3 text-center" }, [
              _vm._v(_vm._s(_vm.prescription.exercise))
            ]),
            _c("CCol", { staticClass: "patient-info-item ml-3 text-center" }, [
              _vm._v(_vm._s(_vm.prescription.sleep))
            ])
          ],
          1
        ),
        _c(
          "table",
          {
            staticClass: "mt-5",
            staticStyle: { color: "black" },
            attrs: { width: "100%" }
          },
          [
            _vm.chartType == "summary" || _vm.chartType == "ox"
              ? _c("tr", [_vm._m(1)])
              : _vm._e(),
            _vm.chartType == "summary" || _vm.chartType == "ox"
              ? _c("tr", { staticClass: "data-label" }, [
                  _c("td"),
                  _c(
                    "td",
                    {
                      staticClass: "text-center",
                      staticStyle: { border: "solid 1px black" }
                    },
                    [_vm._v("最大値")]
                  ),
                  _c("td", { staticClass: "text-center" }, [_vm._v("最小値")]),
                  _c("td", { staticClass: "text-center" }, [_vm._v("平均値")]),
                  _c("td", { staticClass: "text-center" }, [_vm._v("中央値")]),
                  _c("td", { staticClass: "text-center" }, [_vm._v("標準偏差")])
                ])
              : _vm._e(),
            _vm.chartType == "summary" || _vm.chartType == "ox"
              ? _c("tr", [
                  _vm._m(2),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.ox_setting["max"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.ox_setting["min"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.ox_setting["avg"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.ox_setting["middle"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      _vm._s(
                        _vm.measureValueSummary.ox_setting["standardDeviation"]
                      )
                    )
                  ])
                ])
              : _vm._e(),
            _vm.chartType == "summary" || _vm.chartType == "ox"
              ? _c("tr", [
                  _vm._m(3),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.ox_breath["max"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.ox_breath["min"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.ox_breath["avg"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.ox_breath["middle"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      _vm._s(
                        _vm.measureValueSummary.ox_breath["standardDeviation"]
                      )
                    )
                  ])
                ])
              : _vm._e(),
            _vm.chartType == "summary" || _vm.chartType == "ox"
              ? _c("tr", [
                  _c(
                    "td",
                    {
                      staticStyle: { border: "none" },
                      attrs: { colspan: "6" }
                    },
                    [
                      _c("div", { staticClass: "text-center" }, [
                        _c("span"),
                        _vm._v(
                          "稼働時間:" +
                            _vm._s(_vm.measureValueSummary["kadouJikan"]) +
                            _vm._s(_vm.oxHourMark) +
                            " "
                        ),
                        _c("span", { staticClass: "ml-3" }, [
                          _vm._v(
                            "装着時間:" +
                              _vm._s(_vm.measureValueSummary["souchakuJikan"]) +
                              _vm._s(_vm.oxBreathHourMark)
                          )
                        ]),
                        _c("span", { staticClass: "ml-3" }, [
                          _vm._v("装着率:" + _vm._s(_vm.souchakuritsu))
                        ])
                      ]),
                      _c("div", { staticStyle: { "font-size": "11px" } }, [
                        _vm._v(
                          "※酸素濃縮装置により検出された数値を表示していますので、"
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "11px",
                            "padding-left": "8px"
                          }
                        },
                        [_vm._v("実際の数値とは異なる場合があります。")]
                      )
                    ]
                  )
                ])
              : _vm._e(),
            _vm._m(4),
            _vm.chartType == "summary" || _vm.chartType == "pu"
              ? _c("tr", { staticClass: "mt-3" }, [_vm._m(5)])
              : _vm._e(),
            _vm.chartType == "summary" || _vm.chartType == "pu"
              ? _c("tr", { staticClass: "data-label" }, [
                  _c("td"),
                  _c("td", { staticClass: "text-center" }, [_vm._v("最大値")]),
                  _c("td", { staticClass: "text-center" }, [_vm._v("最小値")]),
                  _c("td", { staticClass: "text-center" }, [_vm._v("平均値")]),
                  _c("td", { staticClass: "text-center" }, [_vm._v("中央値")]),
                  _c("td", { staticClass: "text-center" }, [_vm._v("標準偏差")])
                ])
              : _vm._e(),
            _vm.chartType == "summary" || _vm.chartType == "pu"
              ? _c("tr", [
                  _vm._m(6),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.spo2["max"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.spo2["min"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.spo2["avg"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.spo2["middle"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      _vm._s(_vm.measureValueSummary.spo2["standardDeviation"])
                    )
                  ])
                ])
              : _vm._e(),
            _vm.chartType == "summary" || _vm.chartType == "pu"
              ? _c("tr", [
                  _vm._m(7),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.pr_bpm["max"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.pr_bpm["min"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.pr_bpm["avg"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.pr_bpm["middle"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      _vm._s(
                        _vm.measureValueSummary.pr_bpm["standardDeviation"]
                      )
                    )
                  ])
                ])
              : _vm._e(),
            _vm.chartType == "summary" || _vm.chartType == "pu"
              ? _c("tr", [
                  _c(
                    "td",
                    {
                      staticStyle: { border: "none" },
                      attrs: { colspan: "7" }
                    },
                    [
                      _c("div", { staticClass: "text-center" }, [
                        _vm._v(
                          "表示期間のパルスオキシメータ測定回数 " +
                            _vm._s(
                              _vm.measureValueSummary.pr_bpm["measureCount"]
                            ) +
                            " 回"
                        )
                      ])
                    ]
                  )
                ])
              : _vm._e(),
            _vm.chartType == "bl" ? _c("tr", [_vm._m(8)]) : _vm._e(),
            _vm.chartType == "bl"
              ? _c("tr", { staticClass: "data-label" }, [
                  _c("td"),
                  _c("td", { staticClass: "text-center" }, [_vm._v("最大値")]),
                  _c("td", { staticClass: "text-center" }, [_vm._v("最小値")]),
                  _c("td", { staticClass: "text-center" }, [_vm._v("平均値")]),
                  _c("td", { staticClass: "text-center" }, [_vm._v("中央値")]),
                  _c("td", { staticClass: "text-center" }, [_vm._v("標準偏差")])
                ])
              : _vm._e(),
            _vm.chartType == "bl"
              ? _c("tr", [
                  _vm._m(9),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.bl_up["max"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.bl_up["min"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.bl_up["avg"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.bl_up["middle"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      _vm._s(_vm.measureValueSummary.bl_up["standardDeviation"])
                    )
                  ])
                ])
              : _vm._e(),
            _vm.chartType == "bl"
              ? _c("tr", [
                  _vm._m(10),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.bl_down["max"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.bl_down["min"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.bl_down["avg"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.bl_down["middle"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      _vm._s(
                        _vm.measureValueSummary.bl_down["standardDeviation"]
                      )
                    )
                  ])
                ])
              : _vm._e(),
            _vm.chartType == "bl"
              ? _c("tr", [
                  _vm._m(11),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.bl_bpm["max"] || ""))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.bl_bpm["min"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.bl_bpm["avg"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.bl_bpm["middle"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      _vm._s(
                        _vm.measureValueSummary.bl_bpm["standardDeviation"]
                      )
                    )
                  ])
                ])
              : _vm._e(),
            _vm.chartType == "bl"
              ? _c("tr", [
                  _c(
                    "td",
                    {
                      staticStyle: { border: "none" },
                      attrs: { colspan: "6" }
                    },
                    [
                      _c("div", { staticClass: "text-center" }, [
                        _vm._v(
                          "表示期間の血圧計の測定回数 " +
                            _vm._s(_vm.measureValueSummary.bl_up["hour"]) +
                            " 回"
                        )
                      ])
                    ]
                  )
                ])
              : _vm._e()
          ]
        ),
        _c(
          "label",
          {
            staticClass: "mt-2 mb-0",
            staticStyle: { "font-size": "4mm", color: "black" }
          },
          [_vm._v("メモ")]
        ),
        _c(
          "pre",
          {
            staticStyle: {
              "font-size": "4mm",
              color: "black",
              border: "1px black solid",
              "padding-left": "5px",
              width: "100%",
              height: "150px"
            }
          },
          [_vm._v(_vm._s(_vm.$store.state.memo) + "\n\n          ")]
        )
      ],
      1
    ),
    _c("div", { staticClass: "right" }, [
      ["summary", "ox"].indexOf(_vm.chartType) >= 0
        ? _c(
            "div",
            [
              _vm.dayCount < 30
                ? _c("ox-charts7-print", {
                    attrs: {
                      "day-count": _vm.dayCount,
                      "from-series": _vm.serieObject["OX_SETTING"],
                      "chart-width": _vm.widthHeight.OX_SETTING_WIDTH,
                      "chart-height": "170mm",
                      "x-title": _vm.oxChartXTitle,
                      "y-title": _vm.oxChartYTitle,
                      "day-from-to": _vm.dayFromTo
                    }
                  })
                : _c("ox-charts90-print", {
                    attrs: {
                      "day-count": _vm.dayCount,
                      "from-series": _vm.serieObject["OX_SETTING"],
                      "chart-width": _vm.widthHeight.OX_SETTING_WIDTH,
                      "chart-height": "170mm",
                      "x-title": _vm.oxChartXTitle,
                      "y-title": _vm.oxChartYTitle,
                      "day-from-to": _vm.dayFromTo
                    }
                  })
            ],
            1
          )
        : _vm._e(),
      _vm.chartType == "summary"
        ? _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "chart chart2" },
                [
                  _vm.dayCount < 30
                    ? _c("ox-breath-charts-print", {
                        attrs: {
                          "day-count": _vm.dayCount,
                          "from-series":
                            _vm.serieObject["SUMMARY_OX_BREATH_17"],
                          "day-from-to": _vm.dayFromTo,
                          "chart-title": "呼吸数",
                          ymin: 10,
                          ymax: 35,
                          "x-title": _vm.breath17XTitle,
                          "y-title": "回/分",
                          "chart-width": "710",
                          "chart-height": "170mm"
                        }
                      })
                    : _c("ox-breath-charts30-print", {
                        attrs: {
                          "day-count": _vm.dayCount,
                          "from-series":
                            _vm.serieObject["SUMMARY_OX_BREATH_3090"],
                          "chart-width": "710",
                          "chart-height": "170mm",
                          "day-from-to": _vm.dayFromTo,
                          "chart-title": "呼吸数",
                          ymin: 10,
                          ymax: 35,
                          "x-title": "日付",
                          "y-title": "回/分"
                        }
                      })
                ],
                1
              ),
              _vm.dayCount < 30
                ? _c("ox-spo2-charts-print", {
                    attrs: {
                      "day-count": _vm.dayCount,
                      "chart-title": "血中酸素飽和度/脈拍数",
                      "from-series": _vm.serieObject["SUMMARY_PU_SPO2_17"],
                      "day-from-to": _vm.dayFromTo,
                      ymin: _vm.getSpo2YaxisPointMin(
                        _vm.serieObject["SUMMARY_PU_SPO2_17"],
                        80
                      ),
                      ymax: 100,
                      "x-title": _vm.spo17Title,
                      "y-title": "%",
                      colors: ["#6600FF"],
                      "chart-width": "710",
                      "chart-height": "170mm"
                    }
                  })
                : _c("ox-breath-charts30-print", {
                    attrs: {
                      "day-count": _vm.dayCount,
                      "chart-title": "血中酸素飽和度/脈拍数",
                      "from-series": _vm.serieObject["SUMMARY_PU_SPO2_3090"],
                      "day-from-to": _vm.dayFromTo,
                      ymin: _vm.getSpo2YaxisMin(
                        _vm.serieObject["SUMMARY_PU_SPO2_3090"],
                        80
                      ),
                      ymax: 100,
                      "x-title": "日付",
                      "y-title": "%",
                      colors: ["#6600FF"],
                      "chart-width": "710",
                      "chart-height": "170mm"
                    }
                  }),
              _vm.dayCount < 30
                ? _c("ox-pulse-charts-print", {
                    attrs: {
                      "day-count": _vm.dayCount,
                      "from-series": _vm.serieObject["SUMMARY_PU_MYAKUHAKU_17"],
                      "day-from-to": _vm.dayFromTo,
                      ymin: _vm.getMyakuhakuYaxisPointMin(
                        _vm.serieObject["SUMMARY_PU_MYAKUHAKU_17"],
                        60
                      ),
                      ymax: _vm.getMyakuhakuYaxisPointMax(
                        _vm.serieObject["SUMMARY_PU_MYAKUHAKU_17"],
                        180
                      ),
                      "x-title": _vm.spo17Title,
                      "y-title": "回/分",
                      colors: ["#FF0000"],
                      "chart-width": "710",
                      "chart-height": "170mm"
                    }
                  })
                : _c("ox-breath-charts30-print", {
                    staticStyle: { "margin-top": "-40px" },
                    attrs: {
                      "day-count": _vm.dayCount,
                      "chart-title": "",
                      "from-series":
                        _vm.serieObject["SUMMARY_PU_MYAKUHAKU_3090"],
                      "day-from-to": _vm.dayFromTo,
                      ymin: _vm.getMyakuhakuYaxisMin(
                        _vm.serieObject["SUMMARY_PU_MYAKUHAKU_3090"],
                        60
                      ),
                      ymax: _vm.getMyakuhakuYaxisMax(
                        _vm.serieObject["SUMMARY_PU_MYAKUHAKU_3090"],
                        180
                      ),
                      "show-xasis": false,
                      colors: ["#FF0000"],
                      "y-title": "回/分",
                      "chart-width": "710",
                      "chart-height": "170mm"
                    }
                  })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.chartType == "ox",
              expression: "chartType == 'ox'"
            }
          ]
        },
        [
          _c(
            "div",
            { staticClass: "chart chart2" },
            [
              _vm.dayCount < 30
                ? _c("ox-breath-charts-print", {
                    attrs: {
                      "day-count": _vm.dayCount,
                      "from-series": _vm.serieObject["OX_BREATH_17"],
                      "chart-title": "呼吸数",
                      "day-from-to": _vm.dayFromTo,
                      ymin: 10,
                      ymax: 35,
                      "x-title": _vm.breath17XTitle,
                      "y-title": "回/分",
                      "chart-width": "710",
                      "chart-height": "200"
                    }
                  })
                : _c("ox-breath-charts30-print", {
                    attrs: {
                      "day-count": _vm.dayCount,
                      "from-series": _vm.serieObject["OX_BREATH_3090"],
                      "day-from-to": _vm.dayFromTo,
                      "chart-title": "呼吸数",
                      ymin: 10,
                      ymax: 35,
                      "x-title": "日付",
                      "y-title": "回/分",
                      "chart-width": "710",
                      "chart-height": "200"
                    }
                  })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "chart chart3", staticStyle: { height: "250px" } },
            [
              _c(
                "div",
                { staticStyle: { display: "inline-block", width: "50%" } },
                [
                  _c("breath-pie-print", {
                    attrs: {
                      "day-count": _vm.dayCount,
                      "chart-title": "設定流量の割合",
                      "from-series-lables": _vm.serieObject["OX_PIE"]
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { display: "inline-block", width: "50%" } },
                [
                  _c("breath-column-print", {
                    attrs: {
                      "day-count": _vm.dayCount,
                      "chart-title": "呼吸数の分布",
                      "from-series-and-category":
                        _vm.serieObject["OX_BREATH_COLUMN_DATA"],
                      "x-title": "呼吸数(回/分)",
                      "y-title": "頻度(回)"
                    }
                  })
                ],
                1
              )
            ]
          )
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.chartType == "pu",
              expression: "chartType == 'pu'"
            }
          ]
        },
        [
          _c(
            "div",
            { staticClass: "chart chart3" },
            [
              _vm.dayCount < 30
                ? _c("spo2-charts-print", {
                    attrs: {
                      "day-count": _vm.dayCount,
                      "chart-title": "血中酸素飽和度/脈拍数",
                      "from-series": _vm.serieObject["PU_SPO2_17"],
                      "day-from-to": _vm.dayFromTo,
                      ymin: _vm.getSpo2YaxisPointMin(
                        _vm.serieObject["PU_SPO2_17"],
                        80
                      ),
                      ymax: 100,
                      "x-title": _vm.spo17Title,
                      "y-title": "%",
                      colors: ["#6600FF"],
                      "chart-width": "710"
                    }
                  })
                : _c("breath-charts30-print", {
                    attrs: {
                      "day-count": _vm.dayCount,
                      "chart-title": "血中酸素飽和度/脈拍数",
                      "from-series": _vm.serieObject["PU_SPO2_3090"],
                      "day-from-to": _vm.dayFromTo,
                      ymin: _vm.getSpo2YaxisMin(
                        _vm.serieObject["PU_SPO2_3090"],
                        80
                      ),
                      ymax: 100,
                      "x-title": "日付",
                      "y-title": "%",
                      colors: ["#6600FF"],
                      "chart-width": "710"
                    }
                  }),
              _vm.dayCount < 30
                ? _c("pulse-charts-print", {
                    staticStyle: { "margin-top": "-20px" },
                    attrs: {
                      "day-count": _vm.dayCount,
                      "chart-title": "",
                      "from-series": _vm.serieObject["PU_MYAKUHAKU_17"],
                      "day-from-to": _vm.dayFromTo,
                      ymin: _vm.getMyakuhakuYaxisPointMin(
                        _vm.serieObject["PU_MYAKUHAKU_17"],
                        60
                      ),
                      ymax: _vm.getMyakuhakuYaxisPointMax(
                        _vm.serieObject["PU_MYAKUHAKU_17"],
                        180
                      ),
                      "y-title": "回/分",
                      colors: ["#FF0000"],
                      "chart-width": "710"
                    }
                  })
                : _c("breath-charts30-print", {
                    attrs: {
                      "day-count": _vm.dayCount,
                      "chart-title": "",
                      colors: ["#FF0000"],
                      "from-series": _vm.serieObject["PU_MYAKUHAKU_3090"],
                      "y-title": "回/分",
                      "day-from-to": _vm.dayFromTo,
                      ymin: _vm.getMyakuhakuYaxisMin(
                        _vm.serieObject["PU_MYAKUHAKU_3090"],
                        60
                      ),
                      ymax: _vm.getMyakuhakuYaxisMax(
                        _vm.serieObject["PU_MYAKUHAKU_3090"],
                        180
                      ),
                      "chart-width": "710"
                    }
                  })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "chart chart3", staticStyle: { height: "250px" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "inline-block",
                    width: "50%",
                    "text-align": "center"
                  }
                },
                [
                  _c("breath-column-print", {
                    attrs: {
                      "day-count": _vm.dayCount,
                      "chart-title": "血中酸素飽和度の分布",
                      "from-series-and-category":
                        _vm.serieObject["PU_SPO2_COLUMN_DATA"],
                      ymin: 80,
                      ymax: 100,
                      tickAmount: 1,
                      "x-title": "血中酸素飽和度(%)",
                      "y-title": "頻度(回)",
                      colors: ["#6600FF"]
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "inline-block",
                    width: "50%",
                    "text-align": "right"
                  }
                },
                [
                  _c("breath-column-print", {
                    attrs: {
                      "day-count": _vm.dayCount,
                      "chart-title": "脈拍数の分布",
                      "x-title": "脈拍数(回/分)",
                      "y-title": "頻度(回)",
                      "from-series-and-category":
                        _vm.serieObject["PU_MYAKUHAKU_COLUMN_DATA"],
                      ymin: 60,
                      ymax: 180,
                      colors: ["#FF0000"]
                    }
                  })
                ],
                1
              )
            ]
          )
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.chartType == "bl",
              expression: "chartType== 'bl'"
            }
          ]
        },
        [
          _c(
            "div",
            { staticClass: "chart chart2" },
            [
              _vm.dayCount < 30
                ? _c("spo2-charts-print", {
                    attrs: {
                      "day-count": _vm.dayCount,
                      "chart-title": "血圧",
                      "chart-height": "350",
                      "from-series": _vm.serieObject["BL_17"],
                      "day-from-to": _vm.dayFromTo,
                      ymin: _vm.getBLYaxisMinMax(
                        _vm.serieObject["BL_17"],
                        60,
                        true
                      ),
                      ymax: _vm.getBLYaxisMinMax(
                        _vm.serieObject["BL_17"],
                        180,
                        false
                      ),
                      colors: ["#FF33CC", "#3399FF"],
                      "x-title": _vm.bl17Title,
                      "y-title": "mmHg"
                    }
                  })
                : _c("breath-charts30-print", {
                    attrs: {
                      "day-count": _vm.dayCount,
                      "chart-title": "血圧",
                      "chart-height": "350",
                      "from-series": _vm.serieObject["BL_3090"],
                      "day-from-to": _vm.dayFromTo,
                      ymin: _vm.getBL3090YaxisMinMax(
                        _vm.serieObject["BL_3090"],
                        60,
                        true
                      ),
                      ymax: _vm.getBL3090YaxisMinMax(
                        _vm.serieObject["BL_3090"],
                        180,
                        false
                      ),
                      colors: ["#FF33CC", "#3399FF"],
                      "x-title": "日付",
                      "y-title": "mmHg"
                    }
                  })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "chart chart2" },
            [
              _vm.dayCount < 30
                ? _c("spo2-charts-print", {
                    attrs: {
                      "day-count": _vm.dayCount,
                      "chart-title": "脈拍数",
                      "chart-height": "350",
                      "from-series": _vm.serieObject["BL_HEART_17"],
                      "day-from-to": _vm.dayFromTo,
                      ymin: _vm.getBLYaxisMinMax(
                        _vm.serieObject["BL_HEART_17"],
                        60,
                        true
                      ),
                      ymax: _vm.getBLYaxisMinMax(
                        _vm.serieObject["BL_HEART_17"],
                        180,
                        false
                      ),
                      colors: ["#FF0000"],
                      "x-title": _vm.bl17Title,
                      "y-title": "回/分"
                    }
                  })
                : _c("breath-charts30-print", {
                    attrs: {
                      "day-count": _vm.dayCount,
                      "chart-title": "脈拍数",
                      "chart-height": "350",
                      "from-series": _vm.serieObject["BL_HEART_3090"],
                      "day-from-to": _vm.dayFromTo,
                      ymin: _vm.getBL3090YaxisMinMax(
                        _vm.serieObject["BL_HEART_3090"],
                        60,
                        true
                      ),
                      ymax: _vm.getBL3090YaxisMinMax(
                        _vm.serieObject["BL_HEART_3090"],
                        180,
                        false
                      ),
                      colors: ["#FF0000"],
                      "x-title": "日付",
                      "y-title": "回/分"
                    }
                  })
            ],
            1
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "patient-info no-print-area mt-3" }, [
      _c("div", { staticClass: "patient-info-item" }, [
        _vm._v("処方流量(リットル/分)")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "td",
      { staticStyle: { border: "none" }, attrs: { colspan: "6" } },
      [
        _c("label", { staticStyle: { "font-weight": "bold" } }, [
          _vm._v("酸素濃縮装置")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("div", { staticClass: "ml-2" }, [_vm._v("流量（リットル/分）")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("div", { staticClass: "ml-2" }, [_vm._v("分時呼吸数(回/分)")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c(
        "td",
        {
          staticClass: "device",
          staticStyle: { border: "none" },
          attrs: { colspan: "6" }
        },
        [_c("label", { staticStyle: { color: "transparent" } }, [_vm._v("-")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "td",
      {
        staticClass: "device",
        staticStyle: { border: "none" },
        attrs: { colspan: "6" }
      },
      [
        _c("label", { staticStyle: { "font-weight": "bold" } }, [
          _vm._v("パルスオキシメータ")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("div", { staticClass: "ml-2" }, [_vm._v("血中酸素飽和度(％)")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("div", { staticClass: "ml-2" }, [_vm._v("脈拍数(回/分)")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "td",
      {
        staticClass: "device",
        staticStyle: { border: "none" },
        attrs: { colspan: "7" }
      },
      [
        _c("label", { staticStyle: { "font-weight": "bold" } }, [
          _vm._v("血圧計")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("div", { staticClass: "ml-2" }, [_vm._v("収縮期血圧(mmHg)")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("div", { staticClass: "ml-2" }, [_vm._v("拡張期血圧(mmHg)")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("div", { staticClass: "ml-2" }, [_vm._v("脈拍数(回/分)")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }